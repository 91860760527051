@import url('../../node_modules/bootstrap-icons/font/bootstrap-icons.min.css');
@import url('../../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('../../node_modules/aos/dist/aos.css');

:root {
  --yellow-color: #FCE501;
  --green-color: #00A859;
  --gray-color: #606062;
  --orange-color: #FF6000;
  --carousel-item-left-translate-x-lg: 850px;
  --carousel-item-right-translate-x-lg: -850px;
  --carousel-item-left-translate-x-xl: 1200px;
  --carousel-item-right-translate-x-xl: -1200px;
}


@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-Black.ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham/Gotham-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

body {
  min-height: 100%;
  background-color: #111;
  font-family: 'Gotham';
}

/* Centrado del logo usando flexbox */
.navbar {
  justify-content: space-between;
}

.navbar-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-background {
  background: #ccc;
  background-image: url('../../src/images/Common/navbar-bk.png');
  background-size: 100% 100%;
  padding: 10px 0px 10px 0px;
  /* box-shadow: inset #262626 0 0px 0px 1px, inset rgba(38, 38, 38, 0.8) 0 -1px 5px 4px, inset rgba(0, 0, 0, 0.25) 0 -1px 0px 3px, inset rgba(255, 255, 255, 0.7) 0 3px 3px 4px, rgba(0, 0, 0, 0.15) 0 5px 6px 6px, rgba(0, 0, 0, 0.2) 0 6px 6px -1px; */
}

.navbar-logo img {
  width: 20%;
}

.outside-office-bk {
  /* background-color: #e2e2e2; */
  background-image: url("../../src/images/Home/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-y: -50px; */
  height: 100vh;
  background-position: center;
}

#outside-office-banner {
  left: 50%;
  top: 25%;
  margin: 0 auto;
  position: fixed;
}

.banner-text-primary,
.banner-text-secondary,
.text-yellow {
  letter-spacing: -1px;
}

.banner-text-primary,
.banner-text-secondary {
  font-weight: bold;
}

.text-bolder {
  font-weight: bolder;
}

.text-bold {
  font-weight: bold;
}

.text-shadow {
  text-shadow: 1px 2px 1px #111;
}

.text-yellow {
  color: var(--yellow-color);
}

#outside-office-cars-wrapper {
  height: 90vh;
  position: relative;
}

#outside-office-cars {
  position: absolute;
  bottom: 10px;
  z-index: 0;
  width: 90%;
}

#outside-office-cars-left {
  z-index: 1;
}


#outside-office-cars-center {
  z-index: -1;
  /* margin-top: 70px; */
  /* margin-left: 50px; */
}


#btn-access-wrapper {
  height: auto;
  margin: 0 auto;
  position: sticky;
  z-index: 1;
  bottom: 10%;
}

#btn-access-container {
  position: absolute;
  bottom: 25%;
}

/* #link-getinto img {
  max-width: 50%;
  margin-bottom: 20px;
} */

.office-bk {
  background-color: var(--gray-color);
  background-image: url("../../src/images/Office/bk_office.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

#stage-container {
  margin: 0 auto;
  text-align: center;
  position: relative;
}

#stage {
  /* background-image: url('../images/Carousel/stage.png'); */
  width: 50%;
  min-height: 200px;
  position: absolute;
  bottom: 0px;
  left: 25%;
}

/*#region CAROUSEL 3D CARS */

.carousel-item-detail {
  color: #fff;
  /* text-align: center; */
  margin: auto;
  vertical-align: middle;
  position: absolute;
  top: 25%;
  left: 25%;
}

/* .carousel-3d-controls {
  position: absolute !important;
  top: 50% !important;
} */

.carousel-3d-controls a {
  color: #fff !important;
  width: 14rem !important;
  height: 14rem !important;
  text-align: center !important;
  font-size: 14rem !important;
  /* text-shadow: -2px 5px 7px #000;
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; */
}

.carousel-3d-controls a.prev {
  /* left: 8% !important; */
  width: 14rem !important;
  height: 14rem !important;
  /* background-image: url('../images/Carousel/arrow-left.png'); */
}

.carousel-3d-controls a.next {
  /* right: 8% !important; */
  width: 14rem !important;
  height: 14rem !important;
  /* background-image: url('../images/Carousel/arrow-right.png'); */
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}


.carousel-3d-container {
  /* height: 100% !important; */
  overflow: visible !important;
}

.carousel-3d-slide {
  background-color: transparent !important;
  border: none !important;
  overflow: visible !important;
}

.carousel-3d-slide::after {
  background-color: #fff;
}

.carousel-3d-slide.left-1,
.carousel-3d-slide.right-1 {
  /* transform: translateX(-500px) translateZ(-1600px) rotateY(35deg) !important; */
  /* opacity: 0.8 !important; */
}

.carousel-3d-slide.current {
  overflow: visible;
  transform: translate(0%, 10%) !important;
}

.carousel-3d-container {
  margin-bottom: 10vh !important;
  height: fit-content !important;
}

@media (width <=1280px) {
  .carousel-3d-controls a.prev {
    left: 5% !important;
    width: 7rem !important;
    height: 7rem !important;
  }

  .carousel-3d-controls a.next {
    right: 5% !important;
    width: 7rem !important;
    height: 7rem !important;
  }
}

@media (width <=1440px) {
  .carousel-3d-controls a.prev {
    /* left: 5% !important; */
    width: 10rem !important;
    height: 10rem !important;
  }

  .carousel-3d-controls a.next {
    /* right: 5% !important; */
    width: 10rem !important;
    height: 10rem !important;
  }
}

/*#endregion CAROUSEL 3D CARS */


/*

====================================== CAR DATA AND SUBSCRIPTION ===================================

*/

#car-data-container {
  text-align: left;
  position: relative;
  margin-left: 10px;
}

#car-data {
  position: absolute;
  bottom: 20vh;
  right: 5%;
}

.car-data-name,
.car-data-quote {
  font-size: 1.2rem;
  line-height: 7px;
}

.subscription-info {
  line-height: 5px;
}

#btn-more-info-car {
  -webkit-border-radius: 7;
  -moz-border-radius: 7;
  border-radius: 7px;
  -webkit-box-shadow: 1px 6px 7px #000000;
  -moz-box-shadow: 1px 6px 7px #000000;
  box-shadow: 1px 6px 7px #000000;
  font-family: Arial;
  color: #ffffff;
  font-size: 16px;
  background: var(--orange-color);
  text-decoration: none;
  border: none;
  font-weight: bold;
}

@media(width <=1024px) {

  #outside-office-cars {
    bottom: 50px;
  }

  #outside-office-cars-left img {
    max-width: 150% !important;
  }

  #outside-office-cars-center img {
    max-width: 125% !important;
  }

  #outside-office-cars-right img {
    max-width: 140% !important;
  }
}


@media(width=1152px) {
  #outside-office-cars-left img {
    max-width: 140% !important;
  }

  #outside-office-cars-center img {
    max-width: 125% !important;
  }

  #outside-office-cars-right img {
    max-width: 130% !important;
  }
}

@media(width=1280px) {
  #outside-office-cars-left img {
    max-width: 130% !important;
  }

  #outside-office-cars-center img {
    max-width: 115% !important;
  }

  #outside-office-cars-right img {
    max-width: 120% !important;
  }
}


@media(min-height: 864px) {
  #outside-office-cars {
    bottom: 50px;
  }
}

@media(668px <=width <=789px) {

  .carousel-3d-container {
    margin-bottom: 30vh !important;
    height: fit-content !important;
  }

  .carousel-3d-slider {
    width: 700px !important;
    height: 600px !important;
  }

  .carousel-3d-controls a {
    font-size: 6rem !important;
  }

  .carousel-3d-controls a.prev {
    width: 1rem !important;
    height: 1rem !important;
    left: 0px !important;
  }

  .carousel-3d-controls a.next {
    width: 1rem !important;
    height: 1rem !important;
    right: 0px !important;
  }
}

@media(790px <=width <=863px) {

  .carousel-3d-container {
    margin-bottom: 30vh !important;
    height: fit-content !important;
  }

  .carousel-3d-slider {
    width: 700px !important;
    height: 600px !important;
  }

  .carousel-3d-slide {
    width: 700px !important;
    height: 350px !important;
  }

  .carousel-3d-controls a {
    font-size: 7rem !important;
  }

  .carousel-3d-controls a.prev {
    width: 3rem !important;
    height: 3rem !important;
    left: 0px !important;
  }

  .carousel-3d-controls a.next {
    width: 3rem !important;
    height: 3rem !important;
    right: 0px !important;
  }
}

@media(864px <=width <=999px) {

  .carousel-3d-container {
    margin-bottom: 30vh !important;
    height: fit-content !important;
    margin-top: -10px !important;
  }

  .carousel-3d-slider {
    width: 700px !important;
    height: 600px !important;
  }

  .carousel-3d-slide {
    width: 700px !important;
    height: 350px !important;
  }

  .carousel-3d-controls a {
    font-size: 7rem !important;
  }

  .carousel-3d-controls a.prev {
    width: 7rem !important;
    height: 7rem !important;
    left: 0px !important;
  }

  .carousel-3d-controls a.next {
    width: 7rem !important;
    height: 7rem !important;
    right: 0px !important;
  }
}

@media(1000px <=width <=1024px) {

  .carousel-3d-container {
    margin-bottom: 30vh !important;
    height: fit-content !important;
  }

  .carousel-3d-slider {
    width: 700px !important;
    height: 600px !important;
  }

  .carousel-3d-slide {
    width: 700px !important;
    height: 350px !important;
  }

  .carousel-3d-controls a {
    font-size: 10rem !important;
  }

  .carousel-3d-controls a.prev {
    width: 10rem !important;
    height: 10rem !important;
    left: 0px !important;
  }

  .carousel-3d-controls a.next {
    width: 10rem !important;
    height: 10rem !important;
    right: 0px !important;
  }
}

@media(1025px <=width <=1366px) {

  .carousel-3d-container {
    margin-bottom: 30vh !important;
    height: fit-content !important;
  }

  .carousel-3d-slider {
    width: 700px !important;
    height: 600px !important;
  }

  .carousel-3d-slide {
    width: 700px !important;
    height: 350px !important;
  }

  .carousel-3d-slide.left-1,
  .carousel-3d-slide.right-1 {
    width: 800px !important;
    height: 400px !important;
  }

  .carousel-3d-slide.left-1 {
    transform: translateX(var(--carousel-item-left-translate-x-lg)) translateZ(-1600px) !important;
  }

  .carousel-3d-slide.right-1{
    transform: translateX(var(--carousel-item-right-translate-x-lg)) translateZ(-1600px) !important;
  }
}

@media(1367px <=width <=1919px) {

  #outside-office-cars-left img {
    max-width: 140% !important;
  }

  #outside-office-cars-center img {
    max-width: 115% !important;
  }

  #outside-office-cars-right img {
    max-width: 130% !important;
  }

  .carousel-3d-slider {
    width: 950px !important;
    height: 800px !important;
  }

  .carousel-3d-slide {
    width: 950px !important;
    height: 400px !important;
  }

  .carousel-3d-slide.left-1 {
    transform: translateX(var(--carousel-item-left-translate-x-lg)) translateZ(-1600px) rotateY(0deg) !important;
  }

  .carousel-3d-slide.right-1 {
    transform: translateX(var(--carousel-item-right-translate-x-lg)) translateZ(-1600px) rotateY(0deg) !important;
  }
}


@media(width >=1920px) {
  /* #link-getinto img {
    max-width: 80%;
    margin-bottom: 20px;
  } */

  .carousel-3d-container {
    margin-top: 15vh !important;
  }

  .carousel-3d-slider {
    width: 1020px !important;
    height: 800px !important;
  }

  .carousel-3d-slide {
    width: 1020px !important;
    height: 510px !important;
  }

  .carousel-3d-slide.left-1 {
    transform: translateX(var(--carousel-item-left-translate-x-xl)) translateZ(-1600px) rotateY(0deg) !important;
  }

  .carousel-3d-slide.right-1 {
    transform: translateX(var(--carousel-item-right-translate-x-xl)) translateZ(-1600px) rotateY(0deg) !important;
  }

  .carousel-3d-slide img {
    width: 90% !important;
  }
}

/* typical phone screen resolution */
@media(width <=667px) and (orientation: portrait) {
  .outside-office-bk {
    background-color: #e2e2e2;
    background-image: url("../../src/images/Home/background_mobile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }

  #outside-office-banner {
    right: 10px;
    top: 13%;
    left: auto;
  }

  #outside-office-banner .banner-text-primary {
    font-size: 1.3rem;
  }

  #outside-office-banner .banner-text-secondary {
    font-size: 1.5rem;
  }

  .card-home .banner-text-primary {
    font-size: 1.3rem;
  }

  .card-home .banner-text-secondary {
    font-size: 1.5rem;
  }

  .card-home {
    top: 20% !important;
    right: 10% !important;
  }

  #section-outside-office {
    overflow: hidden;
  }

  #outside-office-cars-wrapper {
    overflow: hidden;
  }

  #outside-office-cars {
    bottom: 45% !important;
  }

  #outside-office-cars-left img {
    max-width: 350px !important;
    position: absolute;
    left: -45%;
  }

  #outside-office-cars-center img {
    max-width: 200px !important;
  }

  #outside-office-cars-right img {
    max-width: 350px !important;
    position: absolute;
  }

  #btn-access-container {
    bottom: 100px !important;
  }

  /* #link-getinto img{
    max-width: 75%;
  } */
  #btn-access-wrapper {
    bottom: 20%;
  }

  .navbar-logo img {
    width: 35%;
  }

  .carousel-3d-controls a.prev {
    left: -3% !important;
    width: 4rem !important;
    height: 4rem !important;
  }

  .carousel-3d-controls a.next {
    right: -3% !important;
    width: 4rem !important;
    height: 4rem !important;
  }

  #main-office-container {
    position: absolute !important;
    top: unset !important;
    bottom: 20% !important;
  }

  #car-data-container {
    position: absolute !important;
  }

  #car-data {
    position: fixed !important;
    bottom: 10%;
  }

  .carousel-3d-slide img {
    width: 100%;
  }

  .carousel-3d-controls {
    margin-top: 0px !important;
    top: 60% !important;
  }

  #card-info-car {
    transform: translate(0%, 2%) !important;
    /* top: 10% !important; */
    right: unset !important;
    bottom: unset !important;
    width: 100%;
  }

  .car-data-name,
  .car-data-quote {
    font-size: 1.rem;
    line-height: normal;
  }

  .subscription-info {
    line-height: 15px;
    font-size: 1.2rem;
  }

  .button-more-info,
  .card-footer {
    display: none !important;
  }

  .card-body {
    padding-bottom: 0.1rem !important;
  }

  .carousel-3d-controls a {
    font-size: 5rem !important;
  }

  .carousel-3d-controls a.prev {
    /* width: 1rem !important;
    height: 1rem !important; */
    left: -15px !important;
  }

  .carousel-3d-controls a.next {
    /* width: 1rem !important;
    height: 1rem !important; */
    right: -15px !important;
  }

  .button-more-info-mobile {
    position: absolute;
    bottom: 0px !important;
    border-radius: 0px !important;
    padding: 20px !important;
    width: 100% !important;
    font-size: 20px !important;
  }
}

#link-getinto {
  color: #ffffff;
  border: 3px solid transparent;
  background: linear-gradient(rgba(4, 159, 82, 0.6), rgba(4, 159, 82, 0.6)) padding-box, linear-gradient(180deg, transparent, #fff) border-box;
  border-radius: 20px;
  font-size: clamp(30px, 4vw, 70px);
  font-weight: bold;
  letter-spacing: -1px;
}

#link-getinto:hover {
  background: linear-gradient(rgba(4, 159, 82, 0.9), rgba(4, 159, 82, 0.9)) padding-box, linear-gradient(174deg, transparent, rgba(255, 255, 255)) border-box;
}

#link-getinto:active {
  transform: translateY(4px);
  border: none;
}

#main-office-container {
  position: relative;
  /* top: 10%; */
}

#card-info-car {
  position: fixed;
  /* left: 15%;
  top: 10%; */
  transform: translate(15%, 2%);
  background-color: transparent;
  backdrop-filter: blur(15px);
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  transition: all 0.9s ease;
  z-index: 1;
}

/* Clase que aplicará la transformación de escala */
/* #card-info-car.shrink {
  transform: translate(1%, -2%);
} */

.card-footer-info-car {
  border: none;
}

.card-home {
  position: absolute;
  top: 20%;
  right: 5%;
  background-color: transparent;
  backdrop-filter: blur(40px);
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
}

/* CSS */
.button-more-info,
.button-more-info-mobile {
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px var(--green-color);
  background-image: linear-gradient(45deg, var(--green-color) 0%, var(--yellow-color) 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  text-shadow: #222 0px 0px 10px;
  width: 20%;
  font-size: 24px;
}

.button-more-info:hover,
.button-more-info-mobile:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-more-info:active,
.button-more-info-mobile:active {
  transform: scale(0.95);
}

.button-custom-podersa {
  padding: 0.4em 1em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  /* background-image: linear-gradient(45deg, var(--green-color) 0%, var(--yellow-color) 100%); */
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 7px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-weight: bold;
  letter-spacing: -1px;
  opacity: 0.8;
  text-decoration: none;
  bottom: 10px;
}

/* CSS */
.button-85 {
  padding: 0.4em 1em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 7px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: clamp(30px, 4vw, 50px);
  font-weight: bold;
  letter-spacing: -1px;
  opacity: 0.8;
  text-decoration: none;
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg,
      /* #ff0000,
    #ff7300, */
      #fff,
      #fffb00,
      #049f59
      /* #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000 */
    );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}


.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.outside-office-bk .button-85:hover {
  background: #049f59;
  color: #fff;
  /* animation: wiggle 1s; */
  box-sizing: border-box;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}


/* @keyframes wiggle {
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(1.2, 1.1);
  }
  100% {
    transform: scale(1.3, 1.2);
  }
} */

*,
:before,
:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.hide {
  display: none;
}

.page__style {
  background: #6d326d;
  font-family: OpenSans-Regular, sans-serif;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: auto auto;
  overflow: hidden;
}

.page__style .page__description {
  color: #fff;
  font-weight: 300;
  text-align: center;
}

/* .page__style h1 {
  font-weight: 300;
  margin-top: 200px;
  margin-bottom: 30px;
} */
/* .page__style .btn_nav {
  background: #002a32;
  border-radius: 30px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-family: inherit;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
  padding: 17px 30px;
  width: 146px;
} */
.page__style .btn_nav:focus,
.page__style .btn_nav:active {
  outline: none;
}

/* .page__style a {
  text-decoration: none;
  color: #fff;
} */
/* .page__style a:hover {
  text-decoration: underline;
} */
.animate_content {
  background-color: #000;
  animation: animate 2s ease;
}

.fadeIn {
  z-index: 10;
}

/*  home
-----------------------------------*/
.home {
  /* background: #5da9e9; */
  /* background-image: url("../../src/images/Home/background_home.png"); */
  background: #000;
}

/*  Projects
-----------------------------------*/
.projects {
  /* background: #bd5de9; */
  /* background-image: url("../../src/images/Office/bk_office.jpg"); */
  background: #000;
}

/*  Skills
-----------------------------------*/
.skills {
  background: #5de974;
}

/*  About
-----------------------------------*/
.about {
  background: #fb9b33;
}

/*  Contact
-----------------------------------*/
.contact {
  background: #c9cdc0;
}

#office-carousel-container {
  width: 100%;
  transition: all 0.9s ease;
}

/* Clase que aplicará la transformación de escala */
#office-carousel-container.shrink {
  width: 80%;
  transform: scale(0.8);
}


/*****************************************************************
~ Mobile media-queries (max-width: 767px)
******************************************************************/
/* @media only screen and (max-width: 767px) {
  .page__description h1 {
    margin-top: 100px;
  }
} */
@-moz-keyframes animate {
  10% {
    transform: scale(1, 0.002);
  }

  35% {
    transform: scale(0.2, 0.002);
    opacity: 1;
  }

  50% {
    transform: scale(0.2, 0.002);
    opacity: 0;
  }

  85% {
    transform: scale(1, 0.002);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
  }
}

@-webkit-keyframes animate {
  10% {
    transform: scale(1, 0.002);
  }

  35% {
    transform: scale(0.2, 0.002);
    opacity: 1;
  }

  50% {
    transform: scale(0.2, 0.002);
    opacity: 0;
  }

  85% {
    transform: scale(1, 0.002);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
  }
}

@-o-keyframes animate {
  10% {
    transform: scale(1, 0.002);
  }

  35% {
    transform: scale(0.2, 0.002);
    opacity: 1;
  }

  50% {
    transform: scale(0.2, 0.002);
    opacity: 0;
  }

  85% {
    transform: scale(1, 0.002);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes animate {
  10% {
    transform: scale(1, 0.002);
  }

  35% {
    transform: scale(0.2, 0.002);
    opacity: 1;
  }

  50% {
    transform: scale(0.2, 0.002);
    opacity: 0;
  }

  85% {
    transform: scale(1, 0.002);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
  }
}

.button-more-info-mobile {
  display: block;
  position: absolute;
  bottom: 10px;
}

[v-cloak] {
  display: none;
}